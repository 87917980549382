/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// libraries
import React from 'react';
import { bool, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StyledButton from '../../EnterpriseDesignSystem/Button/StyledButton';

const styles = (theme) => ({
    backTop: {
        textAlign: 'center',
        marginBottom: '40px',
        '& img': {
            background: theme.palette.cms?.primary || theme.palette.colorPrimary,
            borderRadius: '50%',
            width: '35px',
            height: '34px',
            padding: '5px',
            display: 'block',
            margin: 'auto',
            marginBottom: '10px',
            cursor: 'pointer',
            '&:hover': {
                transform: 'translateY(-3px)',
                transition: 'all 0.5s ease',
            },
        },
    },
    pushRight: {
        marginLeft: 'auto',
    },

});
const ScrollToTopButton = ({ classes, pushRight }) => {
    const scrollTop = () => {
        if (typeof window !== 'undefined') {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    };
    return (
        <>
            <div data-element="persistNoScroll" className={`${classes.backTop} ${pushRight ? classes.pushRight : ''}`}>
                <img data-element="persistNoScroll" role="presentation" onClick={() => scrollTop()} alt="top-arrow" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDM0OS43NTcgMzQ5Ljc1NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQ5Ljc1NyAzNDkuNzU2OyINCgkgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8Zz4NCgkJPGc+DQoJCQk8Zz4NCgkJCQk8cGF0aCBkPSJNMjk4LjA1NSwxNDcuMTk5YzAsMi43ODctMS4yNjcsOS4yNzEtMTMuMDM2LDkuMjcxaC01NC4wMzd2MTc4Ljg1NmMwLjA3MiwwLjQ4LDAuMTQ0LDEuMTU5LDAuMTQ0LDEuOTM5DQoJCQkJCWMwLDMuMTc3LTEuMjA3LDYuMTA2LTMuNDE2LDguMjY5Yy0yLjg1OCwyLjc5OC03LjIyNCw0LjIyMi0xMi45ODgsNC4yMjJoLTgzLjA1NmMtMTEuNDkzLDAtMTQuNTI1LTguMTQ5LTE0LjUyNS0xMi40NTRWMTYwLjIzNg0KCQkJCQlINjcuNjA2Yy0xMy44NDEsMC0xNS45MDQtNi41OTQtMTUuOTA0LTEwLjUyMWMwLTYuMjkzLDUuOTk2LTEyLjU3Nyw3LjE5Ny0xMy43NzVjMy4xNzYtNC4zMDUsODkuMDM2LTExOC4xOTUsMTAxLjUyOS0xMzAuNzE4DQoJCQkJCWM0LjU1OC00LjUzNCw5LjEzMS01LjMzOSwxMi4xNjMtNS4yMDljNy4yOTMsMC4zLDEyLjI2OCw1LjkxNSwxMi44MDgsNi41NmwxMDQuODM4LDEyNi42NzQNCgkJCQkJQzI5MS43NSwxMzQuNjMxLDI5OC4wNTUsMTQxLjA2OCwyOTguMDU1LDE0Ny4xOTl6IiBmaWxsPSJ3aGl0ZSIvPg0KCQkJPC9nPg0KCQk8L2c+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=" />
                <StyledButton variant="text-only" type="button" size="md" label="back to the top" handleOnClick={() => scrollTop()} />
            </div>
        </>
    );
};

ScrollToTopButton.propTypes = {
    classes: object.isRequired,
    pushRight: bool,
};

ScrollToTopButton.defaultProps = {
    pushRight: false,
};

export default withStyles(styles)(ScrollToTopButton);
